import React, {useEffect, useState} from "react";
import ButtonDisabled from "./img/disabled.png";
import ButtonPressed from "./img/pressed.png";
import ButtonNormal from "./img/normal.png";
import ButtonFocus from "./img/focus.png";

import {Col, Container, Row} from "react-bootstrap";
import "./style.scss";

export type AuthProps = {}

export const Auth: React.FC<AuthProps> = () => {

    const [enabled, setEnabled] = useState<boolean>(true);
    const [hover, setHover] = useState<boolean>(false);
    const [pressed, setPressed] = useState<boolean>(false);

    useEffect(()=> {
        setTimeout(()=> {
            setEnabled(true);
        },1000);
    })

    let imgSrc = ButtonDisabled;
    if (enabled) {

        if (pressed) {
            imgSrc = ButtonPressed;
        } else if (hover) {
            imgSrc = ButtonFocus;
        } else {
            imgSrc = ButtonNormal;
        }
    }


    const onMouseOver = () => {
        setHover(true);
    }
    const onMouseLeave = () => {
        setHover(false);
        setPressed(false);
    }

    const onMouseDown = () => {
        setPressed(true);
    }
    const onMouseUp = () => {
        setPressed(false);
    }
    const onClick = () => {
        if (enabled) {
            setEnabled(false);
            // @ts-ignore
            document.location = GoogleOAuthURL;
        }
    }

    return (
            <Container fluid={true}>
                <Row className={"vh-100"}>
                    <Col className="bg-brand" sm={0} md={0} lg={6} />
                    <Col className="bg-light d-flex align-items-center justify-content-center">
                        <div className="text-center">
                            <svg id="imgLogo" focusable="false" tabIndex={-1}
                                 className="transition-colors duration-500 w-48 desktop:w-52"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 947.36 129.43" width="100%">
                                <linearGradient
                                    id="Gradient1" x1="0" y1="0" x2="100%" y2="100%"
                                     gradientUnits="userSpaceOnUse">
                                    <stop offset="0%" stop-color="#6a739a"/>
                                    <stop offset="100%" stop-color="#081757"/>
                                </linearGradient>

                                <path d="M114.26 97.68c18.8-2.88 33.11-17.54 33.11-35.2 0-16.69-12.78-30.72-30.06-34.63C111.95 11.74 95.29 0 75.57 0 52.26 0 33.23 16.39 32.05 37.02 14.17 38.01 0 51.31 0 67.58c0 15 12.04 27.47 27.93 30.1h86.33Z" style={{fill: "url(#Gradient1)"}}></path>
                                <g style={{fill:"url(#Gradient1)"}}>
                                    <text
                                        style={{
                                            fontFamily: "Fabada",
                                            fontSize: "107.26px",
                                            fontWeight: "bold",
                                            strokeMiterlimit: 10,
                                            strokeWidth: 1,
                                            stroke: "url(#Gradient1)"
                                        }}
                                        transform="matrix(.93 0 0 1 171.28 98.29)">
                                        <tspan x="0" y="0">learning</tspan>
                                    </text>
                                    <text style={{fontFamily: "Fabada", fontSize: "105.6px"}}
                                          transform="translate(639.22 100.6)">
                                        <tspan x="0" y="0">cloud</tspan>
                                    </text>
                                </g>

                            </svg>

                            <p><img className="my-3" id="btnGoogle" alt="Sign in with Google" src={imgSrc}
                                    onMouseOver={onMouseOver}
                                    onMouseLeave={onMouseLeave}
                                    onMouseDown={onMouseDown}
                                    onMouseUp={onMouseUp}
                                    onClick={onClick}
                            /></p>
                        </div>
                    </Col>
                </Row>
            </Container>
    );
}
