
import React from 'react'
import ReactDOM from 'react-dom/client'



import {Auth } from "./Auth.tsx"

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode >
    <Auth  />
  </React.StrictMode>,
)
